import { Alert } from "react-bootstrap";

function ValueDump({value}) {
    if (process.env.NODE_ENV !== 'development') {
        return null;
    }

    return (
        <Alert variant="info" className="mt-4">
            <pre>{JSON.stringify(value, null, 2)}</pre>
        </Alert>
    );
}

export default ValueDump;
