import ValueDump from "../../../../ValueDump/ValueDump";
import useSummary from "../../../../hooks/useSummary";

function SummaryDebug() {
    const summary = useSummary();

    return (
        <ValueDump value={summary} />
    );
}

export default SummaryDebug;
