import "./Result.sass";
import React, {useState} from "react";
import Debug from "../../../utility/Debug";
import Page from "../../Page/Page";
import Header from "../../Page/Header/Header";
import Main from "../../Page/Main/Main";
import Notes from "../Notes/Notes";
import {Link, useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import checkIcon from "../../../../images/ico_conditions_met.svg";
import failIcon from "../../../../images/ico_conditions_failed.svg";
import downloadIcon from "../../../../images/ico_download.svg";
import printIcon from "../../../../images/ico_print.svg";
import restartIcon from "../../../../images/ico_repeat.svg";
import {reset, setResultNotes} from "../../../redux/actions/answers";
import {Button} from "react-bootstrap";
import ConfirmDialog from "../../ConfirmDialog/ConfirmDialog";
import {url, ROUTING_PERSONAL_DATA, ROUTING_RESULT_PAGE_PRINT} from "../../../utility/Routing";
import useResult, {DIAGNOSE_KEINE_ADHS} from "../../hooks/useResult";
import generateAndDownloadPdf from "./ResultPage/Download/generateAndDownloadPdf";
import ResultDebug from "./ResultDebug/ResultDebug";

const DOWNLOAD_FILE_NAME = 'ida-r-auswertung.pdf';

function Result() {
    Debug.info('Rendering component %s', 'Result');

    const dispatch = useDispatch();
    const history = useHistory();

    const result = useResult();
    const {diagnosis, additionalDiagnosis} = result;

    const [notesActive, setNotesActive] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    function handleNotesInput(e) {
        Debug.trace('handleNotesInput', e.target.value);
        dispatch(setResultNotes(e.target.value));
    }

    function handleRestart() {
        Debug.trace('handleRestart')
        dispatch(reset());
        history.push(url(ROUTING_PERSONAL_DATA));
    }

    function handleDownload() {
        Debug.trace('handleDownload');
        generateAndDownloadPdf(result, DOWNLOAD_FILE_NAME).catch((error) => {
            Debug.error(error);
            console.error(error);
        });
    }

    return (
        <Page name="Result">
            <Header
                title="Ergebnis"
                keyPressDisabled={notesActive}
            />

            <Main>
                <div className="explanation text-center mb-4 flex-column flex-sm-row">
                    <img src={diagnosis.key === DIAGNOSE_KEINE_ADHS? failIcon : checkIcon} className="img-fluid mb-2 mb-sm-0 mr-sm-2" alt={diagnosis.key === DIAGNOSE_KEINE_ADHS? 'Fail Icon' : 'Check Icon'}/>
                    <span>{diagnosis.explanation}</span>
                </div>

                <hr/>

                <div className="diagnosis text-center mb-4">
                    <h1>Diagnose: {diagnosis.label}</h1>

                    <p className="criteria">
                        {diagnosis.key === DIAGNOSE_KEINE_ADHS ?
                            'Diagnosekriterien gemäß DMS-IV nicht erfüllt.'
                            : 'Diagnosekriterien gemäß DMS-IV erfüllt.'
                        }
                    </p>

                    {additionalDiagnosis.length > 0 ?
                        <ul className="additional-diagnosis-list list-unstyled">
                            {additionalDiagnosis.map((diagnosis, index) => (
                                <li key={index}>{diagnosis.label}</li>
                            ))}
                        </ul> : null
                    }
                </div>

                <hr className="mb-5"/>

                <Notes
                    notes={diagnosis.notes}
                    active={notesActive}
                    onFocus={setNotesActive.bind(null, true)}
                    onBlur={setNotesActive.bind(null, false)}
                    onChange={handleNotesInput}
                />

                <div className="buttons d-flex justify-content-center align-items-center mt-5">
                    <Button onClick={handleDownload} className="mr-2 ml-2" >
                        Als PDF speichern <img className="img-fluid ml-3" src={downloadIcon} alt="Download Icon"/>
                    </Button>

                    <Link className="mr-2 ml-2 btn btn-default" to={url(ROUTING_RESULT_PAGE_PRINT)} target="_blank" rel="noopener noreferrer">
                        Drucken <img className="img-fluid ml-3" src={printIcon} alt="Print Icon"/>
                    </Link>

                    <Button className="mr-2 ml-2" variant="default" onClick={setShowConfirmDialog.bind(null, true)}>
                        Test neustarten <img className="img-fluid ml-3" src={restartIcon} alt="Restart Icon"/>
                    </Button>
                </div>

                <ResultDebug />
            </Main>
            <ConfirmDialog show={showConfirmDialog} handleClose={setShowConfirmDialog.bind(null, false)} handleConfirm={handleRestart}/>
        </Page>
    );
}

export default Result;
