import "./Footer.sass";
import React from "react";
import {Col, Row} from "react-bootstrap";
import {version} from '../../../../../package.json';
import mediceLogo from "../../../../images/logo_medice.svg";

function Footer() {
    return (
        <Row className="Footer">
            <Col>
                <footer>
                    <img src={mediceLogo} alt="MEDICE Logo" className="img-fluid mr-4 mr-sm-2" style={{ height: "1.75rem" }}/>
                    <div>
                        Revidierte integrierte Diagnose der ADHS im Erwachsenenalter - IDA-R
                    </div>
                    <div className="version text-nowrap ml-2">
                        v {version}
                    </div>
                </footer>
            </Col>
        </Row>
    );
}

export default Footer;
