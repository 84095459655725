import {useSelector} from "react-redux";
import useChapter from "./useChapter";
import {areConditionsMet} from "../../utility/QuestionnaireHelper";

const useSummary = () => {
    const Answers = useSelector(state => state.answers);

    let chapter = useChapter();

    const answersForChapter = Answers.chapters[chapter.index]

    const conditionsMet = areConditionsMet(chapter.index, Answers);

    return {
        chapter,
        answers: answersForChapter,
        conditionsMet,
        chapterIdForCriteria: chapterIdForCriteria(chapter)
    }
}

function chapterIdForCriteria(chapter) {
    const firstChapterIds = [
        "A.2.intro",
        "A.2.integrated",
        "A.2.prefilled"
    ];

    if (firstChapterIds.includes(chapter.id)) {
        return 'ASRS-V1.1';
    }

    return chapter.id;
}

export default useSummary;
