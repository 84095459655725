import ValueDump from "../../../ValueDump/ValueDump";
import useResult from "../../../hooks/useResult";

function ResultDebug() {
    const result = useResult();

    return (
        <ValueDump value={result} />
    );
}

export default ResultDebug;
