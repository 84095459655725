import React from "react";
import PropTypes from "prop-types";
import './Notes.sass';
import plusIcon from "../../../../images/ico_plus.svg";
import shiftIcon from "../../../../images/ico_shift.svg";
import enterIcon from "../../../../images/ico_enter.svg";

const Notes = ({notes, active, onFocus, onBlur, onChange, tabIndex}) => {
    return (
        <div className="Notes mb-4">
            <textarea
                placeholder="Anmerkungen"
                onChange={active ? onChange : null}
                readOnly={false} // This can't be !active, because ipad keyboard won't show if this is dynamic.
                onFocus={onFocus}
                onBlur={onBlur}
                className={active ? 'active' : ''}
                value={notes}
                tabIndex={tabIndex}
            />
            <img className={'notes-plus' + (active ? '' : ' active')} src={plusIcon} alt="Plus Icon"/>
            <p className={'notes-help text-small d-none d-sm-block' + (active ? ' active' : '')}>
                Shift <img className="img-fluid" src={shiftIcon} alt="Shift Icon"/> + Enter <img className="img-fluid enter-icon" src={enterIcon} alt="Enter Icon"/> drücken für einen Zeilenumbruch
            </p>
        </div>
    )
}

Notes.propTypes = {
    notes: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
}

Notes.defaultPropTypes = {
    tabIndex: 0
}

export default Notes;
