import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Debug from "../../utility/Debug";

const useAnswer = () => {
    const { questionIndex, chapterIndex } = useParams();

    const Answers = useSelector(state => state.answers);

    if (!chapterIndex || !questionIndex) {
        Debug.warn('Missing chapterIndex or questionIndex');
        return;
    }

    const { notes, answer, checkedAdditionalQuestions } = Answers.chapters[chapterIndex].questions[questionIndex];
    return {
        notes,
        selectedChoice: answer,
        checkedAdditionalQuestions
    }
}

export default useAnswer;