import "./Summary.sass";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import DOMPurify from "dompurify";
import checkIcon from "../../../../../images/ico_conditions_met.svg";
import failIcon from "../../../../../images/ico_conditions_failed.svg";
import NextButton from "../../NextButton/NextButton";
import Debug from "../../../../utility/Debug";
import Page from "../../../Page/Page";
import Header from "../../../Page/Header/Header";
import Main from "../../../Page/Main/Main";
import useNextUrl from "../../../hooks/useNextUrl";
import useSummary from "../../../hooks/useSummary";
import SummaryDebug from "./SummaryDebug/SummaryDebug";

function Summary() {
    Debug.info('Rendering component %s', 'Summary');

    const history = useHistory()
    const {goToNext} = useNextUrl();

    const {chapter, answers, conditionsMet, chapterIdForCriteria} = useSummary();

    const [keyPressDisabled, setKeyPressDisabled] = useState(false);

    function handleConfirmDialogOpen() {
        setKeyPressDisabled(true);
    }

    function handleConfirmDialogClose() {
        setKeyPressDisabled(false);
    }

    function onQuestionClick(url) {
        history.push(url);
    }

    function handleNext() {
        goToNext();
    }

    return (
        <Page name="Summary">
            <Header
                title={chapter.title + ' Übersicht'}
                showProgressBar={true}
                keyPressDisabled={keyPressDisabled}
                onConfirmDialogOpen={handleConfirmDialogOpen}
                onConfirmDialogClose={handleConfirmDialogClose}
            />

            <Main colProps={{className: 'd-flex flex-column'}}>
                <div className="summary-items">
                    {chapter.questions.map(({number, question: questionText, choices, url}, index) => {
                        const selectedChoice = answers.questions[index].answer;
                        const selectedChoiceText = choices[selectedChoice].choice;
                        const classNames = 'answer choice-' + String(selectedChoice + 1) + '-of-' + String(choices.length);
                        if (!number) {
                            number = String(index + 1)
                        }

                        return (
                            <div key={index} className="summary-item" onClick={onQuestionClick.bind(null, url)}>

                                <div className="number">{number}</div>

                                <div className="question-and-answer">
                                    <div className="question pb-0 pb-sm-1 pr-0 pr-sm-2" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(questionText)}} />
                                    <div className="answer-wrapper">
                                        <div className={classNames} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(selectedChoiceText)}} />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="d-flex justify-content-end justify-content-sm-between align-items-start flex-wrap flex-sm-nowrap mb-5 mt-5">
                    <div className="conditions-met d-flex align-items-center">
                        {
                            conditionsMet ?
                                <>
                                    <img src={checkIcon} alt="Check Icon "/> <span>Kriterien {chapterIdForCriteria} sind erfüllt</span>
                                </>
                                : <>
                                    <img src={failIcon} alt="Fail Icon "/> <span>Kriterien {chapterIdForCriteria} sind nicht erfüllt</span>
                                </>
                        }
                    </div>

                    <NextButton
                        show={true}
                        onClick={handleNext}
                        keyPressDisabled={keyPressDisabled}
                    />
                </div>

                <SummaryDebug />
            </Main>
        </Page>
    );
}

export default Summary;
