import { useEffect } from "react";

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function CookieConsent() {
    useEffect(() => {

        if (!document.getElementById('cc--main')) {
            window.CC = window.initCookieConsent();
            window.CC.run({
                current_lang: 'de',
                autoclear_cookies: true,
                page_scripts: true,

                languages: {
                    de: {
                        consent_modal: {
                            title: 'Datenschutz ist uns wichtig',
                            description: 'Hallo, diese Website verwendet wichtige Cookies, um ihren ordnungsgemäßen Betrieb zu gewährleisten und Tracking-Cookies und vergleichbare Funktionen, um zu verstehen, wie Sie mit ihr interagieren. Letztere werden erst nach Ihrer Zustimmung angelegt. <button type="button" data-cc="c-settings" class="cc-link">Zu den Einstellungen.</button>',
                            primary_btn: {
                                text: 'Alle akzeptieren',
                                role: 'accept_all',
                            },
                            secondary_btn: {
                                text: 'Alle ablehnen',
                                role: 'accept_necessary',
                            },
                        },
                        settings_modal: {
                            title: 'Datenschutz-Einstellungen',
                            save_settings_btn: 'Einstellungen speichern',
                            accept_all_btn: 'Alle akzeptieren',
                            reject_all_btn: 'Alle ablehnen',
                            close_btn_label: 'Schließen',
                            cookie_table_headers: [
                                {col1: 'Name'},
                                {col2: 'Domain'},
                                {col3: 'Gültigkeit'},
                                {col4: 'Beschreibung'},
                            ],
                            blocks: [
                                {
                                    description: 'Hier können Sie einstellen, welche Tracking-Funktionen aktiv sein sollen.',
                                },
                                {
                                    title: 'Notwendige Cookies',
                                    description: 'Diese Cookies sind zur Funktion der Website erforderlich und können in Ihren Systemen nicht deaktiviert werden. In der Regel werden diese Cookies nur als Reaktion auf von Ihnen getätigte Aktionen gesetzt, die einer Dienstanforderung entsprechen, wie etwa dem Festlegen Ihrer Datenschutzeinstellungen, dem Anmelden oder dem Ausfüllen von Formularen. Sie können Ihren Browser so einstellen, dass diese Cookies blockiert oder Sie über diese Cookies benachrichtigt werden. Einige Bereiche der Website funktionieren dann aber nicht. Diese Cookies speichern keine personenbezogenen Daten.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true,
                                    },
                                    cookie_table: [
                                        {
                                            col1: 'cc_cookie',
                                            col2: 'app.ida-r-digital.de',
                                            col3: '182 Tage',
                                            col4: 'Medice',
                                        },
                                    ],
                                },
                                {
                                    title: 'Leistungsmessung',
                                    description: 'Diese Funktionen ermöglichen es uns, Besuche und Verkehrsquellen zu zählen, damit wir die Leistung unserer Website messen und verbessern können. Sie unterstützen uns bei der Beantwortung der Fragen, welche Seiten am beliebtesten sind, welche am wenigsten genutzt werden und wie sich Besucher auf der Website bewegen. Alle von diesen Funktionen erfassten Informationen werden aggregiert und sind deshalb anonym. Wenn Sie diese Funktionen nicht zulassen, können wir nicht wissen, wann Sie unsere Website besucht haben.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false,
                                    },
                                },
                            ],
                        },
                    },
                },
            });
        }

    }, []);

    return null;
}
