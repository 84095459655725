import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Row, Col, useAccordionToggle, Form } from "react-bootstrap";
import './AdditionalQuestions.sass';
import arrayDownIcon from "../../../../../../images/ico_arrow_down.svg";
import Info from "../Info/Info";

const CustomToggle = ({ className, children, eventKey }) => {
    const [isActive, setIsActive] = useState(false)

    const handleToggle = useAccordionToggle(eventKey, () => {
        setIsActive(!isActive);
    });

    const activeClass = isActive ? 'active' : ''

    return (
        <Accordion.Toggle
            eventKey={eventKey}
            className={className + ' ' + activeClass}
            onClick={handleToggle}
            tabIndex={5}
        >
            {children}
        </Accordion.Toggle>
    );
}

const AdditionalQuestions = ({ additionalQuestions, checkedAdditionalQuestions, onChange }) => (
    <div className="AdditionalQuestions mt-4 mt-sm-0">
        <Accordion>
            <div className="text-center">
                <CustomToggle eventKey="0" className="additional-questions-toggle">
                    Optionale Zusatzfragen <img className="img-fluid" src={arrayDownIcon} alt="Pfeil nach unten"/>
                </CustomToggle>
            </div>

            <Accordion.Collapse eventKey="0">
                <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                        <div className="card-body pb-2">
                            <Info>
                                Wenn Sie Ihren Patienten optionale Fragen stellen, können Sie diese hier auswählen, damit auch sie in dem Ergebnis PDF ausgewiesen werden.
                            </Info>
                            <ul className="list-unstyled mb-0">
                                {additionalQuestions.map((additionalQuestion, index) => {
                                    return (
                                        <li className="additional-question mb-sm-2" key={index}>
                                            <Form.Check
                                                id={`additional-question-${index}`}
                                                value={index}
                                                label={<>
                                                    <span className="checkmark"/>{additionalQuestion}
                                                </>}
                                                onChange={onChange}
                                                checked={checkedAdditionalQuestions.includes(String(index))}
                                            />
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Accordion.Collapse>

        </Accordion>
    </div>
)

AdditionalQuestions.propTypes = {
    additionalQuestions: PropTypes.array.isRequired,
    checkedAdditionalQuestions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
}

export default AdditionalQuestions;
