import './App.sass';
import React from "react";
import {Switch, Route, useHistory} from 'react-router-dom'
import {Container} from "react-bootstrap";
import Home from './Home/Home'
import Questionnaire from "./Questionnaire/Questionnaire";
import {path, ROUTING_HOME, ROUTING_LOGIN, ROUTING_QUESTIONNAIRE, ROUTING_RESULT_PAGE_PRINT, url} from "../utility/Routing";
import Debug from "../utility/Debug";
import ErrorPage404 from "./ErrorPage404/ErrorPage404";
import Login from "./Login/Login";
import useLogin from "./hooks/useLogin";
import Print from "./Questionnaire/Result/ResultPage/Print/Print";
import CookieConsent from "./ConsentOverlay/ConsentOverlay";

const App = () => {
    Debug.info('Rendering component %s', 'App');

    const {isLoggedIn, isSecuredPath} = useLogin();
    const history = useHistory();

    if (isSecuredPath && !isLoggedIn) {
        Debug.trace('Redirecting to login');

        history.push(url(ROUTING_LOGIN));
    }

    return (
        <Container fluid className="App">
            <Switch>

                <Route path={path(ROUTING_LOGIN)}>
                    <Login/>
                </Route>

                <Route path={path(ROUTING_HOME)} exact>
                    <Home/>
                </Route>

                <Route path={path(ROUTING_RESULT_PAGE_PRINT)} exact>
                    <Print/>
                </Route>

                <Route path={path(ROUTING_QUESTIONNAIRE)}>
                    <Questionnaire/>
                </Route>

                <ErrorPage404/>

            </Switch>

            <CookieConsent/>
        </Container>
    )
}

export default App;
