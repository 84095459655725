import useAnswer from "../../../../hooks/useAnswer";
import ValueDump from "../../../../ValueDump/ValueDump";

function QuestionDebug() {
    const answer = useAnswer();

    return (
        <ValueDump value={answer} />
    );
}

export default QuestionDebug;
