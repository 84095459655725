import React, {useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Alert} from "react-bootstrap";
import Debug from "../../../utility/Debug";

const MEDICE_LOGIN_URL = 'https://login.medice.de/@@app_login';
const FORGOT_PASSWORD_URL = 'https://login.medice.de/mail_password_form';
const REGISTER_URL = 'https://login.medice.de/register';
const SECRET = 'Victor';

const MediceLogin = ({onLoginSuccess}) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState();

    /**
     * Translates our app env into a valid appstate required by medice login.
     * @returns {string}
     */
    function getAppState() {
        switch (process.env.REACT_APP_ENV) {
            case 'development':
                return 'test';
            case 'test':
                return 'test';
            case 'stage':
                return 'stage';
            case 'production':
                return 'live';
            default:
                throw new Error('Invalid app environment: ' + process.env.REACT_APP_ENV);
        }
    }

    function handleUsernameChange(e) {
        setUsername(e.target.value);
        setError(null);
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value);
        setError(null);
    }

    function handleSubmit(e) {
        Debug.info('handleSubmit');

        e.preventDefault();

        if (!formIsValid()) {
            return;
        }

        setError(null);

        const formData = new FormData();
        formData.append('login', username);
        formData.append('password', password);
        formData.append('appstate', getAppState());
        formData.append('appstate_secret', SECRET);

        fetch(MEDICE_LOGIN_URL, {
            method: 'POST',
            body: formData
        }).then(response => response.text())
            .then(data => {
                Debug.info('Success', data);
                const regex = /(\d{3})(\s-\s)(.*)/i;
                const matches = data.match(regex);
                const code = matches.length >= 2? matches[1] : null;
                const message = matches.length >= 4? matches[3] : null;
                console.log(matches, code, message);

                if (code === '200') {
                    return onLoginSuccess();
                }

                if (message === 'Invalid authentication credentials') {
                    return setError('Bitte überprüfen Sie Ihre Eingaben.');
                }

                return setError('Fehler bei der Verarbeitung der Anfrage.');
            })
            .catch((error) => {
                Debug.error('Error', error);
                setError('Fehler bei der Verarbeitung der Anfrage.');
            });
    }

    function formIsValid() {
        return username && username.length > 0 && password && password.length > 0;
    }

    return (
        <div className="MediceLogin">
            {error?
                <Alert variant="danger">{error}</Alert>: null
            }

            <Form method="post" action={MEDICE_LOGIN_URL} onSubmit={handleSubmit}>
                <Form.Group controlId="username">
                    <Form.Control
                        value={username}
                        name="username"
                        type="text"
                        onChange={handleUsernameChange}
                        autoFocus
                        autoComplete="name"
                        onFocus={e => e.currentTarget.select()}
                        placeholder="Benutzername"
                    />
                </Form.Group>
                <Form.Group controlId="password" className="mt-3">
                    <Form.Control
                        value={password}
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        onChange={handlePasswordChange}
                        onFocus={e => e.currentTarget.select()}
                        placeholder="Passwort"
                    />
                </Form.Group>

                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <a href={FORGOT_PASSWORD_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                            <small>Passwort vergessen?</small>
                        </a>
                        <br/>
                        <a href={REGISTER_URL} target="_blank" rel="noopener noreferrer" className="btn btn-link p-0">
                            <small>Registrieren</small>
                        </a>
                    </div>
                    <div>
                        <Button type="submit" variant="default" disabled={!formIsValid()}>Anmelden</Button>
                    </div>
                </div>
            </Form>
        </div>
    )
}

MediceLogin.propTypes = {
    onLoginSuccess: PropTypes.func.isRequired
}

export default MediceLogin;